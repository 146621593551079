<template>
  <div class="app-header header-shadow" v-if="user">
    <div class="app-header__logo">
      <div class="logo-src" :class="logoClass"></div>
      <div class="header__pane ml-auto" v-if="user.tipo != 'user'">
        <div>
          <button
            type="button"
            class="hamburger close-sidebar-btn hamburger--elastic"
            data-class="closed-sidebar"
          >
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="app-header__mobile-menu" v-if="user.tipo != 'user'">
      <div>
        <button
          type="button"
          class="hamburger hamburger--elastic mobile-toggle-nav"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    <div class="app-header__menu">
      <span>
        <button
          type="button"
          class="
            btn-icon btn-icon-only btn btn-primary btn-sm
            mobile-toggle-header-nav
          "
        >
          <span class="btn-icon-wrapper">
            <i class="fa fa-user"></i>
          </span>
        </button>
      </span>
    </div>
    <div class="app-header__content">
      <div class="app-header-right">
        <!-- <div class="header-dots">
          <div class="dropdown">
            <button
              type="button"
              aria-haspopup="true"
              aria-expanded="false"
              data-toggle="dropdown"
              class="p-0 mr-2 btn btn-link"
            >
              <span class="icon-wrapper icon-wrapper-alt rounded-circle">
                <span class="icon-wrapper-bg bg-danger"></span>
                <i
                  class="
                    icon
                    text-danger
                    icon-anim-pulse
                    ion-android-notifications
                  "
                ></i>
              </span>
            </button>
            <div
              tabindex="-1"
              role="menu"
              aria-hidden="true"
              class="
                dropdown-menu-xl
                rm-pointers
                dropdown-menu dropdown-menu-right
              "
            >
              <div class="dropdown-menu-header">
                <div class="dropdown-menu-header-inner bg-plum-plate">
                  <div
                    class="menu-header-image"
                    style="
                      background-image: url('../assets/images/dropdown-header/abstract4.jpg');
                    "
                  ></div>
                  <div class="menu-header-content text-white">
                    <h5 class="menu-header-title">Mensajes</h5>
                  </div>
                </div>
              </div>
              <div class="grid-menu grid-menu-xl grid-menu-3col">
                <div class="no-gutters row">
                  <div class="col-sm-12 col-xl-12">
                    <a
                      class="
                        btn-icon-vertical
                        btn-square
                        btn-transition
                        btn
                        btn-outline-link
                      "
                    >
                      <i
                        class="
                          fa fa-key
                          icon-gradient
                          bg-night-fade
                          btn-icon-wrapper btn-icon-lg
                          mb-3
                        "
                      ></i
                      >Le recomendamos cambiar la contraseña por defecto</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="header-btn-lg pr-0">
          <div class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="widget-content-left header-user-info">
                <div class="btn-group d-flex flex-row align-items-center">
                  <a
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    class="p-0 btn"
                  >
                    <div class="widget-heading" v-if="user">
                      {{ user.nombre }} {{ user.apellido }}
                      <i class="fa fa-angle-down ml-2 opacity-8"></i>
                    </div>
                  </a>
                  <div
                    tabindex="-1"
                    role="menu"
                    aria-hidden="true"
                    class="
                      rm-pointers
                      dropdown-menu-lg dropdown-menu dropdown-menu-right
                    "
                  >
                    <div class="dropdown-menu-header">
                      <div class="dropdown-menu-header-inner bg-info">
                        <div
                          class="menu-header-image opacity-2"
                          style="
                            background-image: url('../assets/images/dropdown-header/city3.jpg');
                          "
                        ></div>
                        <div class="menu-header-content text-left">
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left">
                                <div class="widget-heading" v-if="user">
                                  {{ user.nombre }} {{ user.apellido }}
                                </div>
                              </div>
                              <div class="widget-content-right mr-2">
                                <button
                                  class="
                                    btn-pill btn-shadow btn-shine btn btn-focus
                                  "
                                  @click="logout"
                                >
                                  Cerrar Sesión
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="scroll-area-xs" style="height: 150px">
                      <div class="scrollbar-container ps">
                        <ul class="nav flex-column">
                          <li class="nav-item-header nav-item">MI CUENTA</li>
                          <li class="nav-item">
                            <router-link
                              :to="{ name: 'MisDatos' }"
                              class="nav-link"
                              >Mis datos
                            </router-link>
                          </li>
                          <li class="nav-item">
                            <router-link
                              :to="{ name: 'CambiarContraseña' }"
                              class="nav-link"
                              >Cambiar contraseña
                            </router-link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vuex from "vuex";
import "bootstrap";
import "metismenu";
import $ from "jquery";
import "@/assets/js/demo";

export default {
  name: "Navbar",

  data() {
    return {
      institucionSelected: false,
      institucionId: null,
    };
  },
  computed: {
    logoClass: () => {
      if (window.location.hostname == "recibos.pampadev.com.ar") {
        return " desarrollo ";
      } else if (window.location.hostname == "hma.misrecibosdigitales.com.ar") {
        return " ima ";
      } else if (
        window.location.hostname == "nsl.misrecibosdigitales.com.ar"
      ) {
        return " isnsl ";
      } else if (
        window.location.hostname == "isnsl.misrecibosdigitales.com.ar"
      ) {
        return " isnsl ";
      } else if (
        window.location.hostname == "diocesis.misrecibosdigitales.com.ar"
      ) {
        return " diocesis ";
      } else {
        return " desarrollo ";
      }
    },
    ...Vuex.mapState(["user"]),
  },
  methods: {
    ...Vuex.mapActions({
      logoutAction: "logout",
    }),
    logout() {
      this.logoutAction().then(() => {
        this.$router.push({
          name: "Login",
        });
      });
    },
  },
  created() {
    // console.log(user);
  },
  mounted() {
    require("@/assets/js/eventosdemenu");
    $(".vertical-nav-menu").metisMenu();
  },
};
</script>
